import React, {useLayoutEffect, useState} from 'react';
import Keyboard from "react-simple-keyboard";
import layout from "simple-keyboard-layouts/build/layouts/russian";
import './Keyboard.css'


const KeyboardComp = (props: {show: boolean, setSelected: any, numOnly: boolean}) => {

    const [isRussian, setRussian] = React.useState<boolean>(true);
    const [effected, setEffected] = useState(false)
    const [shifted, setShifted] = useState(false)

    useLayoutEffect(() => {
        if(!effected){
            setTimeout(()=>{
                setEffected(true)
            }, 500)
        }
    }, [effected]);

    if(!props.show) {
        return null;
    }
    const layout =  {
        rus: {
            default: [
                "\u0451 1 2 3 4 5 6 7 8 9 0 - {bksp}",
                "\u0439 \u0446 \u0443 \u043a \u0435 \u043d \u0433 \u0448 \u0449 \u0437 \u0445 \u044a",
                "\u0444 \u044b \u0432 \u0430 \u043f \u0440 \u043e \u043b \u0434 \u0436 \u044d",
                "🌎 \u044f \u0447 \u0441 \u043c \u0438 \u0442 \u044c \u0431 \u044e {shift}",
                "{space}",
            ],
            shift: [
                '\u0401 ! " \u2116 ; % : ? * ( ) _ {bksp}',
                "\u0419 \u0426 \u0423 \u041a \u0415 \u041d \u0413 \u0428 \u0429 \u0417 \u0425 \u042a",
                "\u0424 \u042b \u0412 \u0410 \u041f \u0420 \u041e \u041b \u0414 \u0416 \u042d",
                "🌎 \u042f \u0427 \u0421 \u041c \u0418 \u0422 \u042c \u0411 \u042e {shift}",
                "{space}",
            ],
        },
        eng: {
            default: [
                "` 1 2 3 4 5 6 7 8 9 0 - {bksp}",
                "q w e r t y u i o p",
                "a s d f g h j k l",
                "🌏 z x c v b n m {shift}",
                "{space}",
            ],
            shift: [
                "~ ! @ # $ % ^ & * ( ) _ {bksp}",
                "Q W E R T Y U I O P",
                'A S D F G H J K L',
                "🌏 Z X C V B N M {shift}",
                "{space}",
            ],
        },
        num: {
            default : [
                "7 8 9",
                "4 5 6",
                "1 2 3",
                "X 0 {bksp}",

            ]
        }
    }



    return (
        !effected ? <div></div> :
        <div style={{position: "fixed", bottom: 0, left: 50, right: 0}}>
            <Keyboard
                display={{
                        "{bksp}" : "⌫",
                        "{space}" : " ",
                        "{shift}" : "⇧",
                    }}
                layout={props.numOnly ? layout.num : isRussian ? layout.rus : layout.eng}
                layoutName={shifted ? "shift" : "default"}
                // onChange={this.onChange}
                onKeyPress={(e, x) => {
                    if(props.numOnly) {
                        if (e === "{bksp}") {
                            props.setSelected((x: string) => Number(x.toString().slice(0, -1)))
                        }
                        else if (e === "X") {
                            props.setSelected("")
                        }
                        else {
                            props.setSelected((p: string) => Number(p + e))
                        }
                    }else {
                        if (e === "🌏") {
                            setRussian(p => !p)
                        } else if (e === "🌎") {
                            setRussian(p => !p)
                        } else if (e === "{bksp}") {
                            props.setSelected((x: string) => x.slice(0, -1))
                        } else if (e === "{shift}") {
                            setShifted(x => !x)
                        } else if (e === "{space}") {
                            props.setSelected((p: string) => p + " ")
                        } else {
                            props.setSelected((p: string) => p + e)
                        }
                    }
                }}
            />
        </div>
    );
};

export default KeyboardComp;