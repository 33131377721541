import React, {useEffect, useState} from 'react';
import {Badge, Button, Menu, MenuProps, Modal, Typography} from "antd";
import {
    DollarOutlined, HomeOutlined, LogoutOutlined, MenuFoldOutlined,
    MenuOutlined, ReloadOutlined, SettingOutlined,
    ShoppingCartOutlined, SlackSquareOutlined
} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom';
import {clear, setBarcodeMap, setCategories, setItems} from "../../store/mainSlice";
import API from "../../API/API";

const CashNavigator = (props: any) => {

    const isAdmin = useSelector((state: any)=>state.main.user?.isAdmin)
    const user = useSelector((state: any)=>state.main.user)
    const needUpdateOrders: boolean = useSelector((state: any)=>state.main.needUpdateOrders)
    const receiptCache: number = useSelector((state: any)=>state.main.receiptCache.length)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = React.useState<boolean>(false)
    useEffect(()=>{
        if(!user) {
            navigate("/")
        }
    }, [navigate, user])
    const items: MenuItem[] = [
        getItem('', '0', collapsed ? <MenuOutlined/> : <MenuFoldOutlined />),
        getItem('Главная страница', '/store', <HomeOutlined />),
        !isAdmin && getItem('Мои заказы', '/myorders', <ShoppingCartOutlined />),
        isAdmin && getItem('Администрация', '/admin',  <SlackSquareOutlined/>),
        isAdmin && getItem('Заказы', '/admin/orders',  <Badge dot={needUpdateOrders} offset={[0, 10]}><ShoppingCartOutlined /></Badge>),
        isAdmin && getItem('Касса', '/cash',  <DollarOutlined />),
        getItem('Настройки', '/settings', <SettingOutlined />),

        user && getItem('Выйти из системы', '/logout', <LogoutOutlined />),
    ];
    return (
        <div style={{height: 50, position: "fixed", top: 1, width: "100%", display: "flex", flexDirection: "column", zIndex: 5, backgroundColor: "white"}}>
            <div style={{height: 50, width: "100%", marginLeft: 25, justifyContent: "space-evenly", alignItems: "center", display: "flex"}}>
                {user && user.name && <Typography.Text style={{fontSize: 20}}>{user.name}</Typography.Text>}

                <Button loading={loading} disabled={loading} onClick={()=>{
                    setLoading(true)

                    API.loadCategories()
                        .then((data)=>{
                            // console.log(data.data)
                            if(data.data.length === 0) return
                            dispatch(setCategories(data.data))
                            return API.getAdminicItems()
                        })
                        .then(itemsData=>{
                            if(itemsData === undefined) return
                            dispatch(setItems(itemsData.data))
                            return API.getBarcodeMaps()
                        }).then(barcodeMaps=>{
                            console.log("bb: ", barcodeMaps)
                            dispatch(setBarcodeMap(barcodeMaps?.data))
                        })
                        .catch(reason => {
                            console.log("reason: ", reason)
                        })
                        .finally(()=>{
                            setLoading(false)
                            })

                }}><ReloadOutlined/></Button>

                <Button
                    style={{background: props.mode === 2 ? "#ff2e2e" : "", color: props.mode === 2 ? "#e6e6e6" : ""}}
                    onClick={()=>{
                        if(props.mode === 1) {
                            props.setMode(2)
                        }
                        else if(props.mode === 2) {
                            props.setMode(3)
                        }else {
                            props.setMode(2)
                        }
                    }}
                >{props.mode === 1 ? "Супер цена" : ""}{props.mode === 2 ? "Оптом" : ""}{props.mode === 3 ? "Розница" : ""}</Button>

                <Badge count={receiptCache}>
                    <Button onClick={()=>{
                        navigate("/cash/receipts")
                    }}>Чеки</Button>
                </Badge>

            </div>
            <div style={{height: 1, width: "100%", backgroundColor: "#868686"}}>
            </div>
            <Menu

                style={{width: collapsed ? 50 : 200, top: 0, left: 0, position: "fixed", height: "100vh"}}
                selectedKeys={[location.pathname]}
                mode="inline"
                theme="dark"
                onClick={e=>{
                    if (e.key==="0"){
                        setCollapsed(!collapsed)
                    }
                    if (e.key === "/logout"){
                        setModalOpen(true)
                    }
                    if(e.key === "/admin"){
                        navigate("/admin")
                    }
                    if(e.key === "/myorders"){
                        navigate("/myorders")
                    }
                    if(e.key === "/settings"){
                        navigate("/settings")
                    }
                    if(e.key === "/store"){
                        navigate("/store")
                    }
                    if(e.key === "/admin/orders"){
                        navigate("/admin/orders")
                    }
                    if(e.key === "/cash"){
                        navigate("/cash")
                    }
                }}
                inlineCollapsed={collapsed}
                items={items}
            />
            <Modal
                open={modalOpen}
                title={"Внимание"}
                onCancel={()=>{
                    setModalOpen(false)
                }}
                footer={
                    <>
                        <Button onClick={()=>(setModalOpen(false))}>Отмена</Button>
                        <Button onClick={()=>{
                            dispatch(clear())
                            setModalOpen(false)
                            localStorage.removeItem("token")
                            localStorage.removeItem("persist:root")
                            navigate("/login")
                        }} type={"primary"}>Выйти</Button>
                    </>
                }
            >
                Выйти из системы?
            </Modal>
        </div>
    );
};
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}


export default CashNavigator;