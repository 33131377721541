import {Button, FloatButton, message, Upload, UploadProps} from "antd";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {ArrowLeftOutlined, UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import gotham from "./gotham_book.otf"
import JSZip from "jszip";
import JsBarcode from "jsbarcode";
import {useSelector} from "react-redux";

const height = 200, width = 480, num: number = -1

const Barcoder = () => {
    const [state, setState] = useState("start")

    const sheet = useSelector((state: any)=> state.main.items)
    console.log(sheet)

    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate();

    return (
        <div>
            <Button loading={loading}
                          onClick={async ()=>{
                              setLoading(true)
                              const myCanvas = document.createElement("canvas")
                              myCanvas.height = height;
                              myCanvas.width = width;

                              const context = myCanvas.getContext("2d");

                              const newCanvas = document.createElement("canvas")

                              const promises: Promise<{ png: string, name: string}>[] = []
                              const ff = new FontFace("Gotham", "url("+gotham+")")
                              document.fonts.add(ff)
                              await ff.load()
                              let count = 0
                              sheet.forEach((datum: unknown) =>{
                                  if (num !== -1 && count >= num){
                                      // return
                                  }
                                  // @ts-ignore
                                  if(!datum.name.includes("АБС")){
                                      return;
                                  }
                                  count++
                                  promises.push(
                                      new Promise((resolve, reject) =>{
                                          try {
                                              createCanvas(context!, newCanvas, datum)
                                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                              // @ts-expect-error
                                              resolve({png: myCanvas.toDataURL(), name: datum.name})

                                          }
                                          catch (error){
                                              reject(error)
                                          }

                                      })
                                  )
                              })
                              const zip = new JSZip();
                              Promise.all(promises).then(async dd=>{

                                  const folder = zip.folder("Этикетки")
                                  for await (const datum of dd){
                                      folder!.file(datum.name.replaceAll("/","-") + ".png", datum.png.substr(datum.png.indexOf(',')+1), {base64: true});
                                  }
                              }).finally(()=>{
                                  setLoading(false)
                                  zip.generateAsync({type:"blob"})
                                      .then(function(content: Blob | MediaSource) {
                                          // see FileSaver.js
                                          const link = document.createElement("a")
                                          link.href = URL.createObjectURL(content)
                                          link.download = "Этикетки.zip"
                                          link.click()
                                          link.remove()
                                      });
                              })
                          }}
                >
                    Скачать
                </Button>
            <FloatButton icon={<ArrowLeftOutlined/>} onClick={()=>{navigate("/", {replace: true})}}></FloatButton>
        </div>
    );
}
export default Barcoder;
const createCanvas = (context: CanvasRenderingContext2D, newCanvas: HTMLCanvasElement, x: unknown) :string  => {
    // const context = myCanvas.getContext("2d")!
    context.clearRect(0, 0, width, height);


    context.fillStyle = "white"
    context.fillRect(0, 0, width, height);



    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const barcode = x.barcode, price:number = x.highPrice, lowPrice: number = x.lowPrice, name = x.name

    if (barcode) {
        JsBarcode(newCanvas, barcode, {
            width: 2,
            height: 60,
            displayValue: false
        })
        // console.log(newCanvas, "w: ", newCanvas.width, newCanvas.height)

        context.drawImage(newCanvas, 0, 90, newCanvas.width, newCanvas.height);

        context.fillStyle = "yellow"
        context.fillRect(newCanvas.width, newCanvas.height - 5, width - newCanvas.width, 130);

        context.strokeStyle="#000000";
        context.lineWidth = 2
        context.strokeRect(0, 0, width, height);

        if (lowPrice !== 0) {
            context.fillStyle = "black"
            context.font = "56px Gotham";
            context.textAlign = "right";
            context.textBaseline = "bottom"
            context.fillText(price.toString() + "₸", width - 10, 190)

            context.font = "32px Gotham";
            context.fillStyle = "red"
            context.fillText("Опт: " + lowPrice.toString() + "₸", width - 10, 125)
        } else {
            context.fillStyle = "black"
            context.font = "64px Gotham";
            context.textAlign = "right";
            context.textBaseline = "bottom"
            context.fillText(price.toString() + "₸", width - 10, 165)

        }
    }
    else {
        context.fillStyle = "yellow"
        context.fillRect(0, 80, width, 120);

        if (lowPrice !== 0) {
            context.fillStyle = "black"
            context.font = "56px Gotham";
            context.textAlign = "center";
            context.textBaseline = "bottom"
            context.fillText(price.toString() + "₸", width / 2, 190)

            context.font = "32px Gotham";
            context.fillStyle = "red"
            context.fillText("Опт: " + lowPrice.toString() + "₸", width / 2, 130)
        } else {
            context.fillStyle = "black"
            context.font = "80px Gotham";
            context.textAlign = "center";
            context.textBaseline = "bottom"
            context.fillText(price.toString() + "₸", width / 2, 180)

        }
    }
    context.fillStyle = "black"
    context.textBaseline = "top"
    // if(name.length > 23){
        context.font = "32px Gotham";
    // }else {
    //     context.font = "48px Gotham";
    // }
    context.textAlign = "left";
    printAtWordWrap(context, name, 10, 10, 40, width - 10)
    return "";//myCanvas.toDataURL("image/png");
    // return myCanvas.toDataURL("image/png");
}

function printAtWordWrap( context: CanvasRenderingContext2D , text: string, x: number, y: number, lineHeight: number, fitWidth: number)
{
    // console.log(context.font)
    fitWidth = fitWidth || 0;

    if (fitWidth <= 0)
    {
        context.fillText( text, x, y );
        return;
    }
    let words = text.split(' ');
    let currentLine = 0;
    let idx = 1;
    while (words.length > 0 && idx <= words.length)
    {
        const str = words.slice(0, idx).join(' ');
        const w = context.measureText(str).width;
        if ( w > fitWidth )
        {
            if (idx==1)
            {
                idx=2;
            }
            context.fillText( words.slice(0,idx-1).join(' '), x, y + (lineHeight*currentLine) );
            currentLine++;
            words = words.splice(idx-1);
            idx = 1;
            if(currentLine > 2){
                break
            }
        }
        else
        {idx++;}
    }
    if  (idx > 0)
        context.fillText( words.join(' '), x, y + (lineHeight*currentLine) );
}