import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {persistor, store} from "./store/store";
import {Provider} from "react-redux";
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Login from "./main/login/Login";
import StorePage from "./main/home/StorePage";
import Admin from "./main/admin/Admin";
import AddItem from "./main/admin/addItem/AddItem";
import AddCategory from "./main/admin/addCategory/AddCategory";
import {PersistGate} from "redux-persist/integration/react";
import CartPage from "./main/cart/CartPage";
import MyOrders from "./main/myorders/MyOrders";
import InvoiceComponent from "./main/myorders/InvoiceComponent";
import OrdinaryStore from "./main/home/OrdinaryStore";
import CashPage from "./main/cash/CashPage";
import AddUser from "./main/admin/addUser/AddUser";
import ItemsTable from "./main/admin/itemsTable/ItemsTable";
import PricedImages from "./main/admin/getPricedImages/PricedImages";
import Barcoder from "./main/admin/Barcoder/Barcoder";
import FastAddItem from "./main/admin/FastAdd/FastAddItem";
import CashReceipts from "./main/cash/CashReceipts";
import AddBarcodeMap from "./main/admin/addBarcodeMap/AddBarcodeMap";
import qz from "qz-tray";
import jsrsasign, {KEYUTIL, KJUR} from "jsrsasign";
import stob64 = jsrsasign.stob64;
import hextorstr = jsrsasign.hextorstr;
import CheckPrice from "./main/admin/FastAdd/CheckPrice";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
    {
        path: "/",
        element: <OrdinaryStore/>,
    },
    {
        path: "/store",
        element: <StorePage/>,
    },
    {
        path: "/cart",
        element: <CartPage/>,
    },
    {
        path: "/login",
        element: <Login/>
    },{
        path: "/admin",
        element: <Admin/>
    },
    {
        path: "/admin/orders",
        element: <MyOrders type={"admin"}/>
    },
    {
        path: "/admin/addItem",
        element: <AddItem mini={false}/>
    },
    {
        path: "/admin/addCategory",
        element: <AddCategory/>
    },
    {
        path: "/admin/addUser",
        element: <AddUser/>
    },
    {
        path: "/admin/itemsTable",
        element: <ItemsTable/>
    },
    {
        path: "/admin/getPricedImages",
        element: <PricedImages/>
    },
    {
        path: "/admin/barcoder",
        element: <Barcoder/>
    },
    {
        path: "/admin/fastadd",
        element: <FastAddItem/>
    },
    {
        path: "/myorders",
        element: <MyOrders type={"user"}/>
    },
    {
        path: "/myorders/invoice/:id",
        element: <InvoiceComponent/>
    },
    {
        path: "/cash",
        element: <CashPage/>
    },
    {
        path: "/cash/receipts",
        element: <CashReceipts/>
    },
    {
        path: "/admin/barcodemap",
        element: <AddBarcodeMap/>
    },
    {
        path: "/admin/checkPrice",
        element: <CheckPrice/>
    }
]);

console.log("call once")

const setPrinter = ()=>{
    var privateKey = "-----BEGIN PRIVATE KEY-----\n" +
        "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDqUbTGvQOy8L+V\n" +
        "8LR8abA4lPZnrQmiPP6F/Q/cbpOXkclXqvWnj8sJDbziPvmzNUcDJ32NLR+gLXK2\n" +
        "kfSxcswMDWR0kNQxnlCc2s1SltNUEZmqpc9825wgHhkr/pUaKEw8ndiUKykWw5LS\n" +
        "Re/2y5iaWa+esb5JKy9iwDDZ0d6UIYGaOVxu7st882SXSe7RYSH88UvmZRz16FXp\n" +
        "29vCLs+5mE9N2vG38Z5qUDQ4P3Ww3gmDK6Q/rWLY8GyJnUmE1dysAk5KjFj8XEKG\n" +
        "79jJB60RjI39PS7lfQNJ/pQ5K4ydZDXRk6UisP2V+wvclG5r1N05jqFA2Iw8FSvU\n" +
        "xngijEQVAgMBAAECggEAU6DBokSHc2/VENCNufGGgUs4/huV2p9mP6/n30ZfyrKv\n" +
        "Z2iOsDTzw9FxXlYXjEwxggCwIJjANszBqkNBT6unA44sEiY2dVcJclHtAdLurYxy\n" +
        "iaMQAIHooNXdKgR8do93VhMH3yngSk2WsxFe6XgUiNIFid/qB/cvvFDXnoX2qGr2\n" +
        "ERje2T9y9gpKuZK7hw2ZWuvzHrx43pwHdEltGUx8xPho4T+ceH4fb1FHWa4Q6/Oa\n" +
        "vmhRU0tV0OLspU8qeTuMYODguhvVrxDup3EMWOGctlVuM3+5oJBZ8kuRqNtKy6XD\n" +
        "18Upk+UuWfX9rxHn30OZfcctXalot8of9sRY8mrEaQKBgQD7kzaNlyq/3LT8eMIA\n" +
        "zx+EUWISuR28u3mcyk0ARxYBq2cyD4P6aCAm5y3+/xvw4S32Nu7key51BFyuIJkq\n" +
        "XW2Vdg9Tt+UczAerpbpd92dPnhRoe5iWlEEoXdfC+HlM1CCVlCVdz0zUQKFfdmKN\n" +
        "ipUD6s/DqqL1Hk2NlrFoNHVngwKBgQDucMsqOyRKyIdsIGXFDje3oCmcZTYhkW8p\n" +
        "BRsLsidtLXWWO0qqyMYxxx1+iw5us9adkyuRkAp2tEgeuOphvO+EWA+5axepsW3a\n" +
        "Z8SkBBrBOZHLnf2seX077jbtv5oyOecEJk3jla/U2TthyZm2fRaDSbQgicR7aamA\n" +
        "KAOY7eU6hwKBgA0EVftHihe5VMgHe4pNLUVMbLWpTGeaj7VUJl3QT8wwUTue3+ZS\n" +
        "Au/gYFnkW07AZ/9oYj4CPaoiWqVL3ID7qpLkM/DSABHYYME55wW7qmXNOSMa2g9Y\n" +
        "hnKPQH2/G4+lHjZ/HtvWwRPucCsfVgQa2pEEUlG+f4YNVlfwJyfMJxL/AoGAT1yB\n" +
        "cpPut2lZEZcS6GycBOUtFF3nj3KtkfSeFN1+G+uWiKNBbeiXdvf0DCtSaWw4EYhr\n" +
        "Zu6PXQUSQA+CC3fsLJt22FsZrCRfEOOa4pBvK0LzENeMGDzzfuABrk4oj3EQQCRT\n" +
        "pl0rAegvyJNmMwQdWyNL7cyM9hqsDPjEgcEkm78CgYAJuayL0XsGUN+efdObg44y\n" +
        "NkLWyD9/wKgD8ZFwMNEjljdPQOrrPKaOLXAaefEoGcT+c8IU041qpW5nm6h9n3WH\n" +
        "sN2JK5/FxrEtdRewNOSjb9UuqgvbVgtKX0DDMKjYgdqh+GDR2jrDriiko+W0Bquh\n" +
        "+6oA0j0L7bpDXFo9+W4J3w==\n" +
        "-----END PRIVATE KEY-----"

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function(toSign) {
        return function(resolve, reject) {
            console.log("sett: sign")
            try {
                var pk = KEYUTIL.getKey(privateKey);
                var sig = new KJUR.crypto.Signature({"alg": "SHA512withRSA"});  // Use "SHA1withRSA" for QZ Tray 2.0 and older
                sig.init(pk);
                sig.updateString(toSign);
                var hex = sig.sign();
                console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
                resolve(stob64(hextorstr(hex)));
            } catch (err: any) {
                console.error("fffffL:", err);
                reject(err);
            }
        };
    });

    qz.security.setCertificatePromise(function (resolve){
        resolve("-----BEGIN CERTIFICATE-----\n" +
            "MIIECzCCAvOgAwIBAgIGAZDMleUQMA0GCSqGSIb3DQEBCwUAMIGiMQswCQYDVQQG\n" +
            "EwJVUzELMAkGA1UECAwCTlkxEjAQBgNVBAcMCUNhbmFzdG90YTEbMBkGA1UECgwS\n" +
            "UVogSW5kdXN0cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMx\n" +
            "HDAaBgkqhkiG9w0BCQEWDXN1cHBvcnRAcXouaW8xGjAYBgNVBAMMEVFaIFRyYXkg\n" +
            "RGVtbyBDZXJ0MB4XDTI0MDcxODIwMDEzM1oXDTQ0MDcxODIwMDEzM1owgaIxCzAJ\n" +
            "BgNVBAYTAlVTMQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYD\n" +
            "VQQKDBJRWiBJbmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMs\n" +
            "IExMQzEcMBoGCSqGSIb3DQEJARYNc3VwcG9ydEBxei5pbzEaMBgGA1UEAwwRUVog\n" +
            "VHJheSBEZW1vIENlcnQwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDq\n" +
            "UbTGvQOy8L+V8LR8abA4lPZnrQmiPP6F/Q/cbpOXkclXqvWnj8sJDbziPvmzNUcD\n" +
            "J32NLR+gLXK2kfSxcswMDWR0kNQxnlCc2s1SltNUEZmqpc9825wgHhkr/pUaKEw8\n" +
            "ndiUKykWw5LSRe/2y5iaWa+esb5JKy9iwDDZ0d6UIYGaOVxu7st882SXSe7RYSH8\n" +
            "8UvmZRz16FXp29vCLs+5mE9N2vG38Z5qUDQ4P3Ww3gmDK6Q/rWLY8GyJnUmE1dys\n" +
            "Ak5KjFj8XEKG79jJB60RjI39PS7lfQNJ/pQ5K4ydZDXRk6UisP2V+wvclG5r1N05\n" +
            "jqFA2Iw8FSvUxngijEQVAgMBAAGjRTBDMBIGA1UdEwEB/wQIMAYBAf8CAQEwDgYD\n" +
            "VR0PAQH/BAQDAgEGMB0GA1UdDgQWBBSm9SZneBYOrwc7/0JLs4EncxnNiTANBgkq\n" +
            "hkiG9w0BAQsFAAOCAQEAMEF4detIIqn5duaZ+KsxgLG5f9bqCbM/bs85g2iKvag7\n" +
            "6KOdScxxqMpAgm3y8w8AOI/dwXbu5rwCg0zz9t7eDVxY6CHc41bJzgDbZK2zZnfT\n" +
            "lNFhXwqnlty6PMbRgr7+vmdrAcuej4VLFS8A2ALd271mVYEEG2xrgwww7z8/tmPL\n" +
            "Mtq2+CcG2Snv6S2vl6yttOS5USUbLdjKFESEg+rzEA9tFnZTC6yLwF5ncnkbxTkb\n" +
            "pr/5/+1ydU3hZrKYCzxCLdOlnAZYeNQUmcI0LalbQMYA3OvAEDrSLk9UfTHvtd3A\n" +
            "P5KoszOdGMwEb1sRnjwJLA6uX3IQB8R3L4GPOHvJ2w==\n" +
            "-----END CERTIFICATE-----"
        )} )
}

setPrinter()

// function onConnect() {
//     console.log("connected: ")
//     const state = store.getState().main
//     if(state.user && state.user.isAdmin){
//         console.log("hehehe")
//         socket.emit("adminlog", {
//             username: state.user.username,
//         })
//     }
// }
//
// function onDisconnect() {
//     console.log("dis")
// }
//
// socket.on('connect', onConnect);
// socket.on('disconnect', onDisconnect);
// socket.on('newOrder', ()=>{
//     console.log("newOrder yeeee")
//     store.dispatch(setUpdateOrders(true))
//     if (!("Notification" in window)) {
//         // Check if the browser supports notifications
//         alert("This browser does not support desktop notification");
//     } else if (Notification.permission === "granted") {
//         // Check whether notification permissions have already been granted;
//         // if so, create a notification
//         const notification = new Notification("Новый заказ");
//         // …
//     } else if (Notification.permission !== "denied") {
//         // We need to ask the user for permission
//         console.log('requesting')
//         Notification.requestPermission().then((permission) => {
//             // If the user accepts, let's create a notification
//             if (permission === "granted") {
//                 const notification = new Notification("Новый заказ");
//                 // …
//             }
//         });
//     }
// });
    root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <RouterProvider router={router} />
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
