import { configureStore } from '@reduxjs/toolkit'
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mainSlice from "./mainSlice";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";


const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

const persistedReducer = persistReducer(persistConfig, mainSlice)


export const store = configureStore({
    reducer: {main: persistedReducer},
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
        {
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }
    ),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the home itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: Com