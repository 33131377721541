import React from 'react';
import {Button, Checkbox, FloatButton, Form, Input, InputNumber, notification, Select, Space} from "antd";
import {ArrowLeftOutlined, ReloadOutlined} from "@ant-design/icons";
import {AddItemInterface, ItemInterface} from "../../../store/Interfaces";
import Uploader from "./Uploader";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import API from "../../../API/API";
import {setBarcodeMap, setCategories, setItems} from "../../../store/mainSlice";
import {codePutter} from "../itemsTable/ItemsTable";

const AddItem = (props: {mini: boolean, setOpen? : any}) => {
    const [data, setData] = React.useState<AddItemInterface>({
        name: "",
        description: "",
        imageSrc: "",
        quantity: null,
        price: null,
        lowPrice: null,
        highPrice: null,
        masterPrice: null,
        inBox: null,
        inBlock: null,
        category: "",
        barcode: "",
        inFastCash: false
    })

    const itemsData: ItemInterface[] = useSelector((state: any) => state.main.items)
    const categories = useSelector((state: any) => state.main.categories)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [loadingCategory, setLoadingCategory] = React.useState<boolean>(false)

    const onFinish = (values: any) => {
        setLoading(true)
        console.log('Success:', values);
        API.addItem(values)
            .then(()=> {
                return API.loadCategories()
            })
            .then((data)=>{
                if(data.data.length === 0) return
                dispatch(setCategories(data.data))
                return API.getAdminicItems()
            })
            .then(itemsData=>{
                if(itemsData === undefined) return
                dispatch(setItems(itemsData.data))
                return API.getBarcodeMaps()
            }).then(barcodeMaps=>{
                console.log("bb: ", barcodeMaps)
                dispatch(setBarcodeMap(barcodeMaps?.data))
                if(props.mini){
                    props.setOpen(false)
                }else {
                    navigate("/admin")
                }
            }).catch(error => {
                console.log("error:", error)
                api.open({type: "error", message: "Нет доступа в интернет"})
                setLoading(false)
            })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const [form] = Form.useForm()
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const [api, contextHolder] = notification.useNotification();


    return (
        <div>
            {contextHolder}
        <Space style={{display:"flex", flexDirection: "column", marginTop: 30}}>
            <FloatButton
                onClick={()=>{
                    navigate("/admin")
                }}
                type={"primary"}
                icon={<ArrowLeftOutlined />}>
            </FloatButton>
            <Form
                name="basic"
                form={form}
                style={{width: "100%"}}
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                onChange={e=>{
                    if((e.target as HTMLElement).id === "basic_name"){
                        setData({...data, name: (e.target as HTMLInputElement).value})
                    }
                    if((e.target as HTMLElement).id === "basic_category"){
                        setData({...data, category: (e.target as HTMLInputElement).value})
                    }
                }}
            >

                <Form.Item<AddItemInterface>
                    label="Наименование"
                    name="name"
                    rules={[{ required: true, message: 'Это поле обязательное' }]}
                >
                    <Input />
                </Form.Item>


                <div style={{display: "flex", flexDirection: "row", marginTop: 0}} >
                    <Form.Item<AddItemInterface>
                        label="Штрих Код"
                        name="barcode"
                    >
                        <Input onKeyPress={e=>{
                            if(e.key === "Enter"){
                                e.preventDefault();
                                let value = (e.target as HTMLInputElement).value

                                itemsData.forEach(item=>{
                                    if(value === item.barcode){
                                        api["error"]({
                                            message: 'Внимание',
                                            description:
                                                'Штрихкод существует в базе данных',
                                            placement: "top",
                                        });
                                    }
                                })
                            }
                        }} />
                    </Form.Item>
                    <Button onClick={()=>{
                        form.setFieldValue("barcode", "")
                        setData({...data, barcode: ""})
                    }} style={{marginRight: 5, marginLeft: 5}} shape={"circle"}>x</Button>
                    <Button onClick={()=>{
                        let value = codePutter(itemsData, [])
                        form.setFieldValue("barcode", value)
                        setData({...data, barcode: value})
                    }}>
                        Авто код
                    </Button>
                </div>

                <Form.Item<AddItemInterface>
                    label="Быстрый товар"
                    name="inFastCash"
                >
                    <Checkbox onChange={e=>{
                        form.setFieldValue("inFastCash", e.target.checked)
                    }} />
                </Form.Item>


                <div style={{display: "flex", flexDirection: "row", marginTop: 0}}>
                <Form.Item<AddItemInterface>
                    label="Категория"
                    name="category"
                    rules={[{ required: true, message: 'Это поле обязательное' }]}
                >
                    <Select
                        style={{minWidth: 200}}
                        showSearch
                        placeholder="Название"
                        optionFilterProp="children"
                        onChange={(value)=>{
                            form.setFieldValue("category", value)
                            setData({...data, category: value})
                        }}
                        filterOption={filterOption}
                        options={categories.map((category: { name: string; })=>{
                            return {
                                value: category.name,
                                label: category.name
                            }
                        })}
                    >
                    </Select>
                </Form.Item>
                <Button loading={loadingCategory} disabled={loadingCategory} onClick={()=>{
                    setLoadingCategory(true)
                    API.loadCategories()
                        .then((data)=>{
                            // console.log(data.data)
                            if(data.data.length === 0) return
                            dispatch(setCategories(data.data))
                        })
                        .catch(reason => {
                            console.log("reason: ", reason)
                        })
                        .finally(()=>{
                            setLoadingCategory(false)
                    })
                }}><ReloadOutlined /></Button>
                </div>


                {!props.mini &&
                    <>
                <Form.Item<AddItemInterface>
                    label="Описание"
                    name="description"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<AddItemInterface>
                    label="Изображение"
                    name="imageSrc"
                    rules={[{ required: false, message: 'Нужно изображение' }]}
                >
                    <Uploader name={data.name} category={data.category} disabled={data.name === "" || data.category === ""} form={form}/>
                </Form.Item> </>
                }
                <Form.Item<AddItemInterface>
                    label="Количество"
                    name="quantity"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <InputNumber />
                </Form.Item>

                {!props.mini &&
                <Form.Item<AddItemInterface>
                    label="В коробке"
                    name="inBox"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <InputNumber />
                </Form.Item>
                }
                <Form.Item<AddItemInterface>
                    label="В блоке"
                    name="inBlock"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <InputNumber />
                </Form.Item>
                
                <Form.Item<AddItemInterface>
                    label="Цена оприходования"
                    name="price"
                    rules={[{ required: true, message: 'Это поле обязательное' }]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item<AddItemInterface>
                    label="Мастер цена"
                    name="masterPrice"
                    rules={[{ required: true, message: 'Это поле обязательное' }]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item<AddItemInterface>
                    label="Оптовая цена"
                    name="lowPrice"
                    rules={[{ required: true, message: 'Это поле обязательное' }]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item<AddItemInterface>
                    label="Розничная Цена"
                    name="highPrice"
                    rules={[{ required: true, message: 'Это поле обязательное' }]}
                >
                    <InputNumber />
                </Form.Item>


                <Form.Item wrapperCol={{ span: 16 }}>
                    <Button loading={loading} disabled={loading} type="primary" htmlType="submit">
                        Отправить
                    </Button>
                </Form.Item>
            </Form>

        </Space>
        </div>
    );
};

export default AddItem;